import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const kaltsiumSulfaat = () => (
  <Layout>
    <SEO title="Puitlaastplaat-paneelid" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>PUITLAASTPANEELID</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/tostetudporandad/alajaamade-susteem/">
          <div className="epo">
            <h2>Alajaamade süsteem</h2>
          </div>
        </Link>
        <Link to="/tostetudporandad/kaltsiumsulfaat-paneelid/">
          <div className="epo">
            <h2>Kaltsiumsulfaat-paneelid</h2>
          </div>
        </Link>
        <Link
          to="/tostetudporandad/puitlaastpaneelid/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Puitlaastpaneelid</h2>
          </div>
        </Link>
      </div>

      <div className="puitlaastplaat-wrapper">
        <div className="puitlaastplaat-text">
          <h3>Kõrge tihedusega puitlaastplaat-paneelid</h3>
          <p>
            Kõrge tihedusega puitlaastplaat-paneelide valik on väga suur.
            Paneelid on toodetud Belgias ning vastavad Euroopa Standardile
            EN12825 ja on testitud vastavalt EN 13501 normidele. Samuti on
            saadaval paneelid, mis on vastavuses British PSA/MOB Standardiga,
            mis on tunduvalt kõrgemate koormus- nõuetega. Paneele on võimalik
            valmistada antistaatilistena või voolujuhtivatena. Paneeli
            pealminemine osa on kas katteta või kaetud alumiinimfooliumi,
            terasplaadi, melamiini, PVC, linoleumi, kummi, vaiba, tugeva
            laminaadi või kiviplaadiga. Paneeli servad on lõigatud 5 kraadise
            nurga alla ning servatud PVC ribaga. Paneeli alumine osa on kas
            haljas või kaetud alumiiniumfooliumiga (niiskuskindluse tagamiseks)
            või siis kaetud galvaniseeritud terasplaadiga, mis on mõeldud
            kõrgete koormustega aladele. Paneelide standardmõõdud on 600 x 600
            mm, kuid soovi korral on võimalikud ka teised mõõdud. Paneelide
            paksus on 38 mm, ilma katteta. Soovi korral on võimalikud muud
            paneelipaksused. Kõik paneelitüübid on testitud.
          </p>
        </div>
        <div className="subpage-img puitlaastplaat-img"></div>
      </div>
    </div>
  </Layout>
)

export default kaltsiumSulfaat
